// extracted by mini-css-extract-plugin
export var downloadFree = "resources-module--downloadFree--7368e";
export var form = "resources-module--form--fdcff";
export var formRow = "resources-module--formRow--f1be3";
export var formRow__2col = "resources-module--formRow__2col--74496";
export var formSubmit = "resources-module--formSubmit--ee70b";
export var intro = "resources-module--intro--c3149";
export var previewImage = "resources-module--previewImage--069a7";
export var shareButton = "resources-module--shareButton--36b6c";
export var splitLayout = "resources-module--splitLayout--e668d";
export var splitLayoutCol = "resources-module--splitLayoutCol--12126";
export var splitLayoutLeft = "resources-module--splitLayoutLeft--334be";
export var splitLayoutRight = "resources-module--splitLayoutRight--914e7";
export var title = "resources-module--title--3ce3f";
export var unorderedList = "resources-module--unorderedList--3592d";
export var whatsInside = "resources-module--whatsInside--aafac";